import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "Componens/common/Button";

import { GiftBannerProps } from "./types";

import styles from "./styles.module.css";

export const GiftBanner: FC<GiftBannerProps> = ({ url }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["main"]}>
      <img
        src="/Courses/bannerMobile.png"
        alt="banner"
        className={styles["imageMobile"]}
      />
      <div className={styles["textContainer"]}>
        <div className={styles["text"]}>{t("cource.gift.description")}</div>
      </div>
      <img
        src="/Courses/bannerDesctop.png"
        alt="banner"
        className={styles["image"]}
      />

      <div className={styles["button"]}>
        <a href={url} target="_blank" rel="noreferrer">
          <Button variant="white">{t("cource.gift.button")}</Button>
        </a>
      </div>
    </div>
  );
};
