interface useOnClickLinkWithOutElementProps {
  btnId: string | string[];
  withAnumation?: boolean;
}

export const useOnClickLinkWithOutElement = ({
  btnId,
  withAnumation = true,
}: useOnClickLinkWithOutElementProps) => {
  const onClickLink: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    let target = e.target as any;

    let btnIds = typeof btnId === "string" ? [btnId] : [...btnId];

    while (target !== document) {
      if (btnIds.includes(target.id)) {
        e.preventDefault();
        return;
      }
      target = target.parentNode;
    }
    if (withAnumation) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return { onClickLink };
};
